
/*
 * Font Family
 */
$font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;

/*
 * Color
 */

// base color code (新しいカラーコードを追加する場合はここに新しい定義を追加）
$color-tatta: #0b56a5;
$color-red: #FF6262;
$color-blue: #0E7FF0;
$color-yellow: #F0B800;
$color-orange: #F06B00;
$color-black: #333333;
$color-grey-dark: #727272;
$color-grey: #979797;
$color-grey-light: #E4E4E4;
$color-white-smoke: #F6F6F6;
$color-white: #FFFFFF;
$color-green: #E5F78A;
$color-deep-green: #9FC53A;

// button (カラーコード直指定禁止）
$color-btn-submit: $color-yellow;
$color-btn-edit: $color-blue;
$color-btn-cancel: $color-grey-light;
$color-btn-detail: $color-blue;
$color-btn-important: $color-red;
$color-btn-select: $color-grey-light;


// border（カラーコード直指定禁止）
$color-border: $color-grey;


/*
 * width / height
 */
$sidenav-width: 230px;
$toolbar-height: 64px;
$footer-height: 50px;

